exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-how-to-help-js": () => import("./../../../src/pages/about/how-to-help.js" /* webpackChunkName: "component---src-pages-about-how-to-help-js" */),
  "component---src-pages-about-thank-you-js": () => import("./../../../src/pages/about/thank-you.js" /* webpackChunkName: "component---src-pages-about-thank-you-js" */),
  "component---src-pages-about-what-we-do-js": () => import("./../../../src/pages/about/what-we-do.js" /* webpackChunkName: "component---src-pages-about-what-we-do-js" */),
  "component---src-pages-about-who-we-are-js": () => import("./../../../src/pages/about/who-we-are.js" /* webpackChunkName: "component---src-pages-about-who-we-are-js" */),
  "component---src-pages-clinics-workshops-consulting-js": () => import("./../../../src/pages/clinics-workshops/consulting.js" /* webpackChunkName: "component---src-pages-clinics-workshops-consulting-js" */),
  "component---src-pages-clinics-workshops-franklin-method-js": () => import("./../../../src/pages/clinics-workshops/franklin-method.js" /* webpackChunkName: "component---src-pages-clinics-workshops-franklin-method-js" */),
  "component---src-pages-clinics-workshops-work-in-hand-js": () => import("./../../../src/pages/clinics-workshops/work-in-hand.js" /* webpackChunkName: "component---src-pages-clinics-workshops-work-in-hand-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-og-og-home-js": () => import("./../../../src/pages/og_og_home.js" /* webpackChunkName: "component---src-pages-og-og-home-js" */),
  "component---src-pages-og-whr-homepage-js": () => import("./../../../src/pages/og_whr_homepage.js" /* webpackChunkName: "component---src-pages-og-whr-homepage-js" */),
  "component---src-pages-programs-js": () => import("./../../../src/pages/programs.js" /* webpackChunkName: "component---src-pages-programs-js" */),
  "component---src-pages-wonder-horses-js": () => import("./../../../src/pages/wonder-horses.js" /* webpackChunkName: "component---src-pages-wonder-horses-js" */)
}

